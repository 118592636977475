import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, Image } from 'react-bootstrap';
import logo from '../Images/logo.png';

function Header() {
  return (
    <header >
      <Navbar bg="primary" variant="dark" expand="md" className="fixed-top" >
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <Image src={logo} alt="Pest_master" height="50" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              {/* <Nav.Link as={Link} to="/services">Services</Nav.Link> */}
              <Nav.Link as={Link} to="/product">Services</Nav.Link>
              <Nav.Link as={Link} to="/career">Career</Nav.Link>
              <Nav.Link as={Link} to="/clients">Clients</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
              <Nav.Link>info@pestmaster.in</Nav.Link>
              <Nav.Link>+91-9833588577</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
