import React, { useState, useEffect } from 'react';
import experience from '../Images/experience-logo.jpg';
import Front from '../Images/Front.jpg';

function CarouselBanner() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768; // Adjust the breakpoint as needed

  const containerStyle = {
    height: '100vh',
    padding: '0', // Remove padding for all screen sizes
  };

  return (
    <div className="container-fluid p-0 d-flex justify-content-center align-items-center" style={containerStyle}>
      <div style={{ width: '100%', height: '100%' }}>
        {isMobile ? (
          <img
            className="d-block w-100 h-100"
            src={Front}
            alt="Front_Image"
            style={{ objectFit: 'contain', objectPosition: 'center', marginTop: '-70px', marginBottom: '0' }} // Set marginBottom to 0
          />
        ) : (
          <img
            className="d-block w-100 h-100"
            src={experience}
            alt="SangTech_banner"
            style={{ objectFit: 'cover' }}
          />
        )}
      </div>
    </div>
  );
}

export default CarouselBanner;
