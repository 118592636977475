import React, { useState, useEffect } from 'react';

function About() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768; // Adjust the breakpoint as needed

  const sectionStyle = {
    marginTop: isMobile ? '-180px' : '0', // Set marginTop to -180px for mobile, 0 for larger screens
  };

  return (
    <section className="about-section text-center" style={sectionStyle}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="display-4">About Pest Control New Idea</h1>
            <h2 className="section-sub-header">Honest &amp; Efficient Work</h2>
            <p className="section-info text-left">
              Welcome to Pest Control New Idea, the leading professional pest control
              company serving the Mumbai area. We take great pride in providing
              top-notch pest control services with a focus on excellence,
              reliability, and customer satisfaction. At Pest Control New Idea, our
              team of well-experienced technicians and professionals is
              dedicated to eliminating pests from your premises. With a strong
              commitment to human health and hygiene, we strive to create safe
              and pest-free environments for homes, societies, and offices. Our
              expert team has successfully treated thousands of premises for
              different pests, including Termites, Cockroaches, Bedbugs,
              Rodents, Mosquitos, Flies, and Birds, among others. We have the
              expertise and skills required to tackle your pest-related issues
              effectively while also being mindful of environmental challenges.
              We understand the importance of a pest-free environment for your
              comfort and peace of mind. Your satisfaction is our top priority,
              and we guarantee the services we offer, ensuring that pests are
              the last subject you need to worry about. <br /><br />
              Pest Control New Idea is committed to delivering quality service that exceeds your
              expectations. We tailor our solutions to your specific needs,
              providing personalized care and attention to every customer. Trust
              Pest Control New Idea to handle your pest problems with efficiency and
              professionalism. Our goal is to make your homes, societies, and
              offices safer and more secure, free from the nuisance of pests.
              Let us help you create a healthier and happier living and working
              space for you and your loved ones.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
