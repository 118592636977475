import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LayOutComponent from './LayOutComponent';
import Card from 'react-bootstrap/Card';

const productData = [
  {
    title: "General PestMaster",
    description: "We offer eco-friendly pest control solutions for cockroaches, ants, spiders, and silverfish. Our herbal gel baiting technology targets cockroaches in hard-to-reach areas, while the odorless chemical spray eliminates ants, spiders, and silverfish from living areas. The treatments are safe for all, including seniors, pregnant women, children, and pets. There's no need to leave your home during or after the treatment, and it's quick, long-lasting, and 100% effective. Choose us for a safe and efficient solution to your pest problems.",
    image: "https://t4.ftcdn.net/jpg/05/60/76/73/360_F_560767361_qOHZYDIJmpHiHZf85uQxuVGWjglYEj7i.jpg"
 
  },
  {
    title: "Termites Master",
    description: "Termites Terminator - Your ultimate solution for termite control. Don't let termites eat away at your peace of mind. Our specialized termite control services will protect your property from these destructive invaders. With our expertise and advanced techniques, we ensure a termite-free zone, giving you the peace of mind you deserve. Choose Termites Terminator and bid farewell to termites for good.",
    image: "https://media.istockphoto.com/id/1284882498/photo/termites-in-the-nest-on-a-white-background-small-animals-are-dangerous-for-habitat.jpg?s=612x612&w=0&k=20&c=ItiaRaJHPyeSSBNn705jo_QOjhiXVEZk4y6_X6279gM="
 
  },
  {
    title: "Rodent Master",
    description: "Rodent Wranglers - Say goodbye to pesky rodents! We are your trusted experts in rodent control, offering effective and humane solutions to keep your property rodent-free. Our team of skilled exterminators knows how to handle these sneaky pests and prevent them from causing any more damage. Don't let rodents run rampant in your space; let Rodent Wranglers take charge and restore your peace of mind",
    image: "https://images.pexels.com/photos/51340/rat-pets-eat-51340.jpeg"
 
  },
  {
    title: "Mosquito Master",
    description: "Mosquito Masters - Your ultimate mosquito elimination specialists! We understand the annoyance and health risks these tiny bloodsuckers pose, which is why we're dedicated to providing top-notch mosquito control services. Our team employs cutting-edge techniques to target and eliminate mosquitoes, allowing you to reclaim your outdoor space without worrying about those bothersome bites. Trust Mosquito Masters to create a mosquito-free environment, so you can enjoy the outdoors in peace",
    image: "https://images.pexels.com/photos/86722/tiger-mosquito-mosquito-asian-tigermucke-sting-86722.jpeg?cs=srgb&dl=pexels-pixabay-86722.jpg&fm=jpg"
 
  },
  {
    title: "Pigeon Master",
    description: "Pigeon Pros - Your trusted bird control experts! We specialize in humane and effective pigeon control solutions. Pigeons can cause extensive damage and health hazards, but worry not, as our skilled team is equipped to handle these pesky birds with care. From deterrent installations to safe removal, we ensure a bird-free zone for your property. Count on Pigeon Pros to keep your premises clean, safe, and free from the disturbances caused by pigeons",
    image: "https://cdn.pixabay.com/photo/2022/03/31/01/05/bird-7102006_640.jpg"
 
  },
  {
    title: "Bedbug Master",
    description: "Bedbug Busters - Your ultimate solution to bedbug infestations! We understand the distress and discomfort that bedbugs bring, and we are here to put an end to it. Our expert team utilizes advanced techniques to eliminate bedbugs from your home, ensuring a peaceful and restful sleep once again. Don't let these tiny pests disrupt your life – trust Bedbug Busters to provide fast, reliable, and effective bedbug control services. Regain control of your space and bid farewell to bedbugs for good",
    image: "https://media.istockphoto.com/id/529654573/photo/bed-bug.jpg?s=612x612&w=0&k=20&c=gBFUnNTdXeXQVLiIfbV8h3mgJQsXoqcaIZYE9VsiEws="
 
  },
  {
    title: "Flies Master",
    description: "Are you tired of being constantly hassled by pesky flies that seem to have invaded every corner of your home or workplace? At FlyAway, we understand the frustration and annoyance caused by these winged nuisances. Flies not only disrupt our daily lives but also pose a significant health risk by carrying and spreading diseases. Fortunately, you no longer need to endure their presence, as we are here to provide the ultimate fly control solutions tailored to your unique needs With years of experience as fly control experts, we have honed our skills and expertise to offer you the most effective and eco-friendly methods to combat these relentless insects.",
    image: "https://images.pexels.com/photos/1046492/pexels-photo-1046492.jpeg?cs=srgb&dl=pexels-thierry-fillieul-1046492.jpg&fm=jpg"
 
  },
  {
    title: "Disinfection Master",
    description: "PureGuard - Your ultimate disinfection specialists! We understand the importance of maintaining a clean and germ-free environment, especially in today's world. At PureGuard, we provide top-notch disinfection services that go beyond surface cleaning. Our team uses advanced techniques and hospital-grade disinfectants to eliminate harmful viruses and bacteria, ensuring a safe and hygienic space for you, your family, and your customers. Trust PureGuard to create a germ-free sanctuary, giving you peace of mind in these uncertain times. Experience the power of true disinfection with PureGuard",
    image: "https://media.istockphoto.com/id/1202033073/photo/cleaning-with-spray-detergent-rubber-gloves-and-dish-cloth-on-work-surface.jpg?s=612x612&w=0&k=20&c=mE83DYw_o2kSAgHmwq5exCjU-im0YZnmj-sEYz8uGXM="
 
  },
  {
    title: "Rat Guard Installation",
    description: "RatSecure - Your reliable rat guard installation experts! Are rodents wreaking havoc on your property? Say goodbye to rodent troubles with RatSecure. We specialize in professional rat guard installation services to protect your home or business from unwanted rodent intrusions. Our team uses durable materials and strategic methods to fortify vulnerable entry points and keep pesky rats at bay. Don't let rodents damage your property and compromise your hygiene. Choose RatSecure for effective and long-lasting rat guard solutions, ensuring a rodent-free environment. Safeguard your premises with RatSecure and bid farewell to those pesky pests",
    image: "https://5.imimg.com/data5/SELLER/Default/2022/8/LI/JR/FN/35131294/rat-guard-500x500.jpg"
 
  }
 
  
];

function OurProductPage() {
  return (
    <LayOutComponent>
      <Container fluid className="text-center">
        {/* Banner Image */}
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mx-auto">
            <img
              src="https://t3.ftcdn.net/jpg/03/18/92/26/360_F_318922637_yIjzYNzNQsU6KZupFizYWor6nsoxqNff.jpg"
              className="img-fluid w-100"
              alt="Our Portfolio Web Banner"
              style={{ height: "33.33vw",marginTop: "90px" }}
            />
          </Col>
        </Row>
      </Container>

      <div className="text-center">
        <h1>Our Services</h1>
        <p>Your trusted partner in pest control and prevention.</p>
        <Row xs={1} md={3} className="g-4">
          {productData.map((item, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Img variant="top" src={item.image} alt={item.title} />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </div>
                 
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </LayOutComponent>
  );
}

export default OurProductPage;
