import React from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone, FaWhatsapp, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return ( 
    <footer className="bg-dark text-light py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5 className="text-uppercase mb-3">Corporate Office</h5>
            <address className="mb-4">
              <FaMapMarkerAlt className="me-2" />
              Shop No-1 Ashirwad Society, Cross Road No 2, Thakur Village, Kandivali (East), Mumbai-400101
            </address>
            <p>
              <FaPhone className="me-2" />
              +91 9833588577
            </p>
          </div>
          <div className="col-md-4">
            <h5 className="text-uppercase mb-3">Branch Office</h5>
            <address className="mb-4">
              <FaMapMarkerAlt className="me-2" />
              Shop No.2, HIRANANDANI MEADOWS, Ghodbunder Rd, Manpada, Thane West, Thane, Maharashtra 400610
            </address>
            <p>
              <FaPhone className="me-2" />
              +91 9545148695
            </p>
          </div>
          <div className="col-md-4">
            <h5 className="text-uppercase mb-3">Branch Office</h5>
            <address className="mb-4">
              <FaMapMarkerAlt className="me-2" />
              Shop No. 4, PALMS RESIDENCY, Suncity Rd, opp. Suncity Police Station, Navghar, Vasai West, Palghar, Vasai-Virar, Maharashtra 401202
            </address>
            <p>
              <FaPhone className="me-2" />
              +91 9833588577
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <p>&copy; 2023 Pest Master All Rights Reserved.</p>
          </div>

          <div className="col-md-6">
            <ul className="list-inline text-end">
              <li className="list-inline-item"><a href="mailto:info@pestmaster.in"><FaEnvelope /></a></li>
              <li className="list-inline-item"><a href="https://www.facebook.com/your-facebook-page"><FaFacebook /></a></li>
              <li className="list-inline-item"><a href="https://twitter.com/your-twitter-handle"><FaTwitter /></a></li>
              <li className="list-inline-item"><a href="https://www.linkedin.com/company/your-linkedin-page"><FaLinkedin /></a></li>
              <li className="list-inline-item"><a href="https://wa.me/9833588577"><FaWhatsapp /></a></li>
            </ul>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            {/* Add your map component here */}
            {/* Replace the iframe with the provided Google Maps embed iframe */}
            <iframe
              title="Pest Master Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.7926511143705!2d72.8749122!3d19.2042567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7753cfc475f%3A0x364be88961ae51e4!2sPest%20Control%20New%20Idea%20-%20Pest%20Control%20Service%20in%20Kandivali!5e0!3m2!1sen!2sin!4v1690362177471!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
            {/* End of map */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
