import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Product({ data }) {
  return (
    <div className="text-center">
      <h1>Our Services</h1>
      <p>Say goodbye to pests with Pest Control New Idea - Your trusted partner in pest control and prevention</p>
      <Row xs={1} md={3} className="g-4">
        {data && data.length > 0 ? (
          data.map((item, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Img variant="top" src={item.image} />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>
                      {item.description}
                    </Card.Text>
                  </div>
                  <div className="d-flex justify-content-center">
                    <a href={item.link} className="btn btn-primary">Know More</a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <>
            {/* Default data with 9 different images, names, and descriptions */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://t4.ftcdn.net/jpg/05/60/76/73/360_F_560767361_qOHZYDIJmpHiHZf85uQxuVGWjglYEj7i.jpg" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>General Pest Control New Idea</Card.Title>
                    <Card.Text>
                      We offer eco-friendly pest control solutions for cockroaches, ants, spiders, and silverfish. Our herbal gel baiting technology targets cockroaches in hard-to-reach areas, while the odorless chemical spray eliminates ants, spiders, and silverfish from living areas. The treatments are safe for all, including seniors, pregnant women, children, and pets. There's no need to leave your home during or after the treatment, and it's quick, long-lasting, and 100% effective. Choose us for a safe and efficient solution to your pest problems.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Add 8 more products here */}
            {/* Termites Control */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://media.istockphoto.com/id/1284882498/photo/termites-in-the-nest-on-a-white-background-small-animals-are-dangerous-for-habitat.jpg?s=612x612&w=0&k=20&c=ItiaRaJHPyeSSBNn705jo_QOjhiXVEZk4y6_X6279gM=" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Termites Control</Card.Title>
                    <Card.Text>
                      Termites Terminator - Your ultimate solution for termite control. Don't let termites eat away at your peace of mind. Our specialized termite control services will protect your property from these destructive invaders. With our expertise and advanced techniques, we ensure a termite-free zone, giving you the peace of mind you deserve. Choose Termites Terminator and bid farewell to termites for good.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Rodent Control */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://images.pexels.com/photos/51340/rat-pets-eat-51340.jpeg" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Rodent Control</Card.Title>
                    <Card.Text>
                      Rodent Wranglers - Say goodbye to pesky rodents! We are your trusted experts in rodent control, offering effective and humane solutions to keep your property rodent-free. Our team of skilled exterminators knows how to handle these sneaky pests and prevent them from causing any more damage. Don't let rodents run rampant in your space; let Rodent Wranglers take charge and restore your peace of mind.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Mosquito Control */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://images.pexels.com/photos/86722/tiger-mosquito-mosquito-asian-tigermucke-sting-86722.jpeg?cs=srgb&dl=pexels-pixabay-86722.jpg&fm=jpg" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Mosquito Control</Card.Title>
                    <Card.Text>
                      Mosquito Controls - Your ultimate mosquito elimination specialists! We understand the annoyance and health risks these tiny bloodsuckers pose, which is why we're dedicated to providing top-notch mosquito control services. Our team employs cutting-edge techniques to target and eliminate mosquitoes, allowing you to reclaim your outdoor space without worrying about those bothersome bites. Trust Mosquito Controls to create a mosquito-free environment, so you can enjoy the outdoors in peace.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Pigeon Control */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://cdn.pixabay.com/photo/2022/03/31/01/05/bird-7102006_640.jpg" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Pigeon Control</Card.Title>
                    <Card.Text>
                      Pigeon Pros - Your trusted bird control experts! We specialize in humane and effective pigeon control solutions. Pigeons can cause extensive damage and health hazards, but worry not, as our skilled team is equipped to handle these pesky birds with care. From deterrent installations to safe removal, we ensure a bird-free zone for your property. Count on Pigeon Pros to keep your premises clean, safe, and free from the disturbances caused by pigeons.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Bedbug Control */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://media.istockphoto.com/id/529654573/photo/bed-bug.jpg?s=612x612&w=0&k=20&c=gBFUnNTdXeXQVLiIfbV8h3mgJQsXoqcaIZYE9VsiEws=" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Bedbug Control</Card.Title>
                    <Card.Text>
                      Bedbug Busters - Your ultimate solution to bedbug infestations! We understand the distress and discomfort that bedbugs bring, and we are here to put an end to it. Our expert team utilizes advanced techniques to eliminate bedbugs from your home, ensuring a peaceful and restful sleep once again. Don't let these tiny pests disrupt your life – trust Bedbug Busters to provide fast, reliable, and effective bedbug control services. Regain control of your space and bid farewell to bedbugs for good.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Flies Control */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://images.pexels.com/photos/1046492/pexels-photo-1046492.jpeg?cs=srgb&dl=pexels-thierry-fillieul-1046492.jpg&fm=jpg" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Flies Control</Card.Title>
                    <Card.Text>
                      FlyAway - Your fly control experts! Flies can be a nuisance and a potential health hazard. At FlyAway, we offer effective and eco-friendly fly control solutions to keep these pesky insects at bay. Our skilled technicians employ innovative methods to remove flies from your premises, creating a cleaner and healthier environment. Don't let flies bother you any longer – let FlyAway handle your fly control needs and enjoy a fly-free zone with ease and convenience.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Disinfection Control */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://media.istockphoto.com/id/1202033073/photo/cleaning-with-spray-detergent-rubber-gloves-and-dish-cloth-on-work-surface.jpg?s=612x612&w=0&k=20&c=mE83DYw_o2kSAgHmwq5exCjU-im0YZnmj-sEYz8uGXM=" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Disinfection Control</Card.Title>
                    <Card.Text>
                      PureGuard - Your ultimate disinfection specialists! We understand the importance of maintaining a clean and germ-free environment, especially in today's world. At PureGuard, we provide top-notch disinfection services that go beyond surface cleaning. Our team uses advanced techniques and hospital-grade disinfectants to eliminate harmful viruses and bacteria, ensuring a safe and hygienic space for you, your family, and your customers. Trust PureGuard to create a germ-free sanctuary, giving you peace of mind in these uncertain times. Experience the power of true disinfection with PureGuard.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* Rat Guard Installation */}
            <Col>
              <Card>
                <Card.Img variant="top" src="https://5.imimg.com/data5/SELLER/Default/2022/8/LI/JR/FN/35131294/rat-guard-500x500.jpg" />
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <Card.Title>Rat Guard Installation</Card.Title>
                    <Card.Text>
                      RatSecure - Your reliable rat guard installation experts! Are rodents wreaking havoc on your property? Say goodbye to rodent troubles with RatSecure. We specialize in professional rat guard installation services to protect your home or business from unwanted rodent intrusions. Our team uses durable materials and strategic methods to fortify vulnerable entry points and keep pesky rats at bay. Don't let rodents damage your property and compromise your hygiene. Choose RatSecure for effective and long-lasting rat guard solutions, ensuring a rodent-free environment. Safeguard your premises with RatSecure and bid farewell to those pesky pests.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default Product;
