import React from 'react'
import LayOutComponent from './LayOutComponent'

import { Container, Row, Col} from 'react-bootstrap';



function ContactUsPage() {
  return (
    <LayOutComponent>
   <Container fluid className="text-center">
  <Row>
    <Col xs={12} sm={12} md={12} lg={12} className="mx-auto">
      <img
        src="https://media.istockphoto.com/id/1416335096/photo/businessman-hand-holding-smart-phone-with-icon-mobile-phone-mail-telephone-and-address.jpg?b=1&s=170667a&w=0&k=20&c=O39_wq7HB2oZHV3pyeZDxFAq0Xb_zNvLKrAIEWDVveY="
        className="img-fluid w-100"
        alt="Our OurPortfolio Web Banner"
        style={{ height: "33.33vw" , marginTop: "90px" } }
      />
    </Col>
  </Row>
</Container>
<form><div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10">
          <div className="card border-0 shadow-lg">
            <div className="card-body p-5">
              <h1 className="mb-5 text-center text-uppercase">Contact Us</h1>
              <p className="lead">For any inquiries or to request our pest control services, you can reach us at:</p>
              <ul className="list-unstyled">
                <li><strong>Corporate Office:</strong> Shop No-1 Ashirwad Society, Cross Road No 2, Thakur Village, Kandivali (East), Mumbai-400101</li>
                <li><strong>Branch Office:</strong> Shop No.2, HIRANANDANI MEADOWS, Ghodbunder Rd, Manpada, Thane West, Thane, Maharashtra 400610</li>
                <li><strong>Branch Office:</strong> Shop No. 4, PALMS RESIDENCY, Suncity Rd, opp. Suncity Police Station, Navghar, Vasai West, Palghar, Vasai-Virar, Maharashtra 401202</li>
              </ul>
              <p className="lead">Contact Numbers:</p>
              <ul className="list-unstyled">
                <li><strong>Corporate Office:</strong> +91-9833588577</li>
                <li><strong>Branch Office:</strong> +91-9545148695</li>
                <li><strong>WhatsApp:</strong> +91-9833588577</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
    </LayOutComponent>
  )
}

export default ContactUsPage